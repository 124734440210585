const dedicatedplansData = [
  {
    id: 1,
    name: 'Ryzen 9 3900X (12c/24t)',
    price: '$129.00',
    cpu: '3.8 GHz',
    storage: '64 GB DDR4',
    memory:'960 GB NVMe SSD',
    bandwidth: '50 TB Transfer',
    yearlyPrice: "$129.00/mo",
    save: "SAVE 19%",
    // features: ['3.8 GHz', '64 GB DDR4', '960 GB NVMe SSD', '50 TB Transfer', '1 Gbit Port Speed', '1 IPv4 Address'],
    url: "https://my.qloudhost.com/store/dedicated/amd-ryzen-9-3900x12c24t"
  },
  {
    id: 2,
    name: 'AMD Ryzen 9 5900X(12c/24t)',
    price: '$149.00',
    yearlyPrice: "$149.00/mo",
    cpu: '3.7 GHz',
    storage: '64 GB DDR4',
    memory:'960 GB NVMe SSD',
    bandwidth: '50 TB Transfer',
    save: "SAVE 12%",
    // features: ['3.7 GHz', '64 GB DDR4', '960 GB NVMe SSD', '50 TB Transfer', '1 Gbit Port Speed', '1 IPv4 Address'],
    url: "https://my.qloudhost.com/store/dedicated/ryzen-9-5900x",
    popular: true
  },
  {
    id: 3,
    name: '2x Intel Xeon E5- 2620 v2(12c/24t)',
    price: '$176.00',
    yearlyPrice: "$176.00/mo",
    cpu: '2.1-2.6 GHz',
    storage: '64 GB DDR4',
    memory:'960 GB NVMe SSD',
    bandwidth: '50 TB Transfer',
    save: "SAVE 12%",
    // features: ['2.1-2.6 GHz', '64 GB DDR4', '960 GB NVMe SSD', '50 TB Transfer', '1 Gbit Port Speed', '1 IPv4 Address'],
    url: "https://my.qloudhost.com/store/dedicated/2x-intel-xeon-e5-2620-v2",
  },
  {
    id: 4,
    name: 'Intel Xeon E5-2680 v3(12c/24t)',
    price: '$194.00',
    yearlyPrice: "$194.00/mo",
    cpu: '2.5-3.3 GHz',
    storage: '64 GB DDR4',
    memory:'960 GB NVMe SSD',
    bandwidth: '50 TB Transfer',
    save: "SAVE 12%",
    // features: ['2.5-3.3 GHz', '64 GB DDR4 ECC*', '960 GB NVMe SSD', '50 TB Transfer', '1 Gbit Port Speed', '1 IPv4 Address'],
    url: "https://my.qloudhost.com/store/dedicated/intel-xeon-e5-2680-v3",
    bestForStreaming: false
  },
  {
      id: 5,
      name: 'Intel Xeon E5-2683 v4(16c/32t)',
      price: '$233.00',
      yearlyPrice: "$233.00/mo",
      cpu: '2.10-3.0 GHz',
    storage: '64 GB DDR4',
    memory:'960 GB NVMe SSD',
    bandwidth: '50 TB Transfer',
      save: "SAVE 13%",
      // features: ['2.10-3.0 GHz', '64 GB DDR4 ECC*', '960 GB NVMe SSD', '50 TB Transfer', '1 Gbit Port Speed', '1 IPv4 Address'],
      url: "https://my.qloudhost.com/store/dedicated/compute-broadwell-ep-intel-xeon-e5-2683-v4",
      bestForStreaming: false
    },
    {
      id: 6,
      name: 'Dual Intel Xeon E5-2683 v4',
      price: '$299.00',
      yearlyPrice: "$299.00/mo",
      cpu: '2.0-3.0 GHz',
    storage: '64 GB DDR4',
    memory:'128GB RAM DDR4',
    bandwidth: '50 TB Transfer',
      save: "SAVE 15%",
      // features: ['2.0-3.0 GHz', '128GB RAM DDR4', '2X960GB NVMe SSD', '50 TB Transfer', '1 Gbit Port Speed', '1 IPv4 Address'],
      url: "https://my.qloudhost.com/store/dedicated/dual-intel-xeon-e5-2683-v4-2x960gb",
      newServer: true,
    },
    {
      id: 7,
      name: 'Dual Intel Xeon E5-2680 v3',
      price: '$299.00',
      yearlyPrice: "$299.00/mo",
      cpu: '2.0-3.0 GHz',
    storage: '64 GB DDR4',
    memory:'2X960GB NVMe SSD',
    bandwidth: '50 TB Transfer',
      save: "SAVE 15%",
      // features: ['2.0-3.0 GHz', '128GB RAM DDR4', '2X960GB NVMe SSD', '50 TB Transfer', '1 Gbit Port Speed', '1 IPv4 Address'],
      url: "https://my.qloudhost.com/store/dedicated/dual-intel-xeon-e5-2680-v3-2x192tb",
      newServer: true,
    },
    {
      id: 8,
      name: 'AMD EPYC 7502P (32c/64t)',
      price: '$349.00',
      yearlyPrice: "$349.00/mo",
      cpu: '2.0-3.0 GHz',
    storage: '64 GB DDR4',
    memory:'2×1.92 TB NVMe SSD',
    bandwidth: 'Unmetered Transfer',
      save: "SAVE 30%",
      // features: ['2.0-3.0 GHz', '256 GB DDR4 ECC*', '2×1.92 TB NVMe SSD', 'Unmetered Transfer', '1 Gbit Port Speed', '1 IPv4 Address'],
      url: "https://my.qloudhost.com/store/dedicated/amd-epyc-7502p",
      bestForStreaming: true,
    },
    {
      id: 9, 
    name: "Intel Xeon Gold 6230",
    price: "$399.00",
    yearlyPrice: " $399.00/mo",
    cpu: '2.1 GHz',
    storage: '256GB RAM DDR4',
    memory:'2X1.92TB NVMe SSD',
    bandwidth: '100TB Transfer',
    save: "Save 30%",
    // features: ["2.1 GHz", "256GB RAM DDR4", "2X1.92TB NVMe SSD", "100TB Transfer", "1 Gbit Port Speed", "1 IPv4 IP Address"],
  url: "https://my.qloudhost.com/store/dedicated/intel-xeon-gold-6230",
    
  }
];

export default dedicatedplansData;