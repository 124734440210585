import React from 'react';
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center p-5 text-center">
      <h1 className="display-1 fw-bold text-danger"><img src="/assets/not-found.png" alt='404 ' className='w-75'/></h1>
      <p className="fs-4 text-muted">The page you are looking for cannot be found.</p>
      <Link to="/" className="btn back-to-home">
        Back to Home
      </Link>
    </div>
  )
}

export default NotFound
